import {Component, Input,Output, OnInit} from '@angular/core';
import {IInstallateur} from '../../../interfaces/IMission';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-installateur-card',
  templateUrl: './installateur-card.component.html',
  styleUrls: ['./installateur-card.component.scss'],
})
export class InstallateurCardComponent implements OnInit {
  @Input() installateur: IInstallateur;
  @Input() canRemove: boolean = false;
  @Output() installateurEmis = new EventEmitter<IInstallateur>();
  constructor() { }

  ngOnInit() {}

  emitInstallateur() {
    console.log('Installateur émis:', this.installateur);
    this.installateurEmis.emit(this.installateur);
  }

}


